var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"main"}},[_c('mv-full-page',{attrs:{"isPointer":false,"isV":_vm.isV,"pages":_vm.pageCount,"page":_vm.currentPage,"isCache":true,"height":"calc(100% - 0.72rem)","transition":{
      duration: '500ms', // 动画时长
      timingFun: 'ease', // 动画速度曲线
      delay: '0s', // 动画延迟
    },"config":{
      disabled: this.localValue
    }},on:{"update:page":function($event){_vm.currentPage=$event},"rollEnd":_vm.onRollEnd},scopedSlots:_vm._u([{key:"page1",fn:function(){return [_c('div',{staticClass:"video"},[_c('div',{staticClass:"video-container"},[_c('video',{ref:"myVideo",staticClass:"video",attrs:{"src":_vm.videoPath,"muted":"","autoplay":"","loop":"","controls":"","playsinline":"","preload":"auto","x5-video-player-type":"h5","webkit-playsinline":"true","x5-playsinline":"true","poster":_vm.videoPath + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast'},domProps:{"muted":true},on:{"contextmenu":function($event){$event.preventDefault();}}})]),_c('div',{staticClass:"copyright"},[_vm._v("广告审查批准文号:苏械广审(视)第270213-02323号 盆底康复治疗仪 医疗器械注册号:苏械注准20252090043 盆底评估训练软件:苏械注准20242212280"),_c('br'),_vm._v("请仔细阅读产品说明书或者在医务人员的指导下购买和使用 禁忌内容或者注意事项详见说明书 南京麦豆健康科技有限公司 ")])])]},proxy:true},{key:"page2",fn:function(){return [_c('div',{staticClass:"banner"},[_c('full-swiper',{attrs:{"hover-scale":false,"datas":_vm.bannerList}})],1)]},proxy:true},{key:"page3",fn:function(){return [_c('div',{staticClass:"stage-mode"},[_c('img',{staticClass:"bg",attrs:{"alt":"","src":require('@/assets/img/product/e10/stage-mode-bg.png')}}),_c('div',{staticClass:"title"},[_vm._v("盆底全周期训练")]),_c('img-swiper',{attrs:{"datas":_vm.imgBanner}})],1)]},proxy:true},{key:"page4",fn:function(){return [_c('div',{staticClass:"train"},[_c('div',{staticClass:"title"},[_vm._v("无械 + 有械")]),_c('div',{staticClass:"sub-title"},[_vm._v("科学多维 分阶修复")]),_c('video',{directives:[{name:"animate",rawName:"v-animate",value:({
           name: 'zoomIn',
        }),expression:"{\n           name: 'zoomIn',\n        }"}],attrs:{"src":"//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-e10-1.mp4","muted":"","autoplay":"","loop":"","playsinline":"true","preload":"auto","x5-video-player-type":"h5","webkit-playsinline":"true","x5-playsinline":"true"},domProps:{"muted":true},on:{"contextmenu":function($event){$event.preventDefault();}}})])]},proxy:true},{key:"page5",fn:function(){return [_c('div',{staticClass:"no-equip"},[_c('img',{directives:[{name:"animate",rawName:"v-animate",value:({
           name: 'fadeInUp',
        }),expression:"{\n           name: 'fadeInUp',\n        }"}],attrs:{"alt":"","src":require('@/assets/img/product/e10/no-equip.png')}}),_c('div',{staticClass:"right"},[_c('div',{directives:[{name:"animate",rawName:"v-animate",value:({
           name: 'fadeInUp',
           delay: 500,
        }),expression:"{\n           name: 'fadeInUp',\n           delay: 500,\n        }"}],staticClass:"title"},[_vm._v("无械跟练 ")]),_c('div',{directives:[{name:"animate",rawName:"v-animate",value:({
           name: 'fadeInUp',
           delay: 700,
        }),expression:"{\n           name: 'fadeInUp',\n           delay: 700,\n        }"}],staticClass:"sub-title"},[_vm._v("定制课程，自主练习 ")])])])]},proxy:true},{key:"page6",fn:function(){return [_c('div',{staticClass:"equip"},[_c('div',{staticClass:"top"},[_c('div',{directives:[{name:"animate",rawName:"v-animate",value:({
           name: 'fadeInUp',
        }),expression:"{\n           name: 'fadeInUp',\n        }"}],staticClass:"title"},[_vm._v("有械训练 ")]),_c('div',{directives:[{name:"animate",rawName:"v-animate",value:({
           name: 'fadeInUp',
           delay: 200
        }),expression:"{\n           name: 'fadeInUp',\n           delay: 200\n        }"}],staticClass:"sub-title"},[_vm._v("盆底治疗 腹直肌修复 ")])]),_c('img-swiper-v2',{directives:[{name:"animate",rawName:"v-animate",value:({
           name: 'fadeInUp',
           delay: 400
        }),expression:"{\n           name: 'fadeInUp',\n           delay: 400\n        }"}],attrs:{"datas":_vm.equipList}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }