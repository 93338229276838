<template>
  <div class="swiper">
    <div class="swiper-item" v-for="(item, i) in datas" :key="i" :class="getImgClass(i)"
         :ref='"img"+i'>
      <img :src="item.path" alt/>
      <div class="content">
        <p class="title">{{ item.title }}</p>
        <p class="desc">{{ item.desc }}</p>
      </div>
    </div>
    <div class="swiper-index">
            <span v-for="(item, i) in datas" :key="i" @click="transIndex(i)">
                <span :class='i==currIndex?"active":""'></span>
            </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImgSwiperV2',
  props: {
    datas: { //数据源（必填）
      type: Array,
      default: () => {
        return [];
      },
    },
    interval: { //轮播间隔（单位：ms）
      type: Number,
      default: () => {
        return 3000;
      },
    },
    animationInterval: { //轮播执行动画间隔（单位：ms）
      type: Number,
      default: () => {
        return 400;
      },
    },
    moveDistance: { //需触发轮播的鼠标移动x轴最小距离（单位：px）
      type: Number,
      default: () => {
        return 100;
      },
    },
  },
  data() {
    return {
      oldCurrentIndex: -1,
      currIndex: 0,
      transing: false,
      rem: 100,
    };
  },
  mounted() {
    this.rem = document.documentElement.style.fontSize.replace('px', '') * 1;
    this.resetItemPosition();
    this.refreshInterval();
  },
  unmounted() {
    clearInterval(this.intervalFun);
  },
  watch: {
    currIndex(oldVal, newVal) {
      if (this.transing) {
        return;
      }
      this.oldCurrentIndex = oldVal;
      this.transing = true;
      this.resetItemPosition();
      setTimeout(() => {
        this.transClear();
      }, this.animationInterval);
    },
  },
  methods: {
    transIndex(i) {
      if (this.transing) {
        return;
      }
      this.currIndex = i;
      this.refreshInterval();
    },
    /**
     刷新定时器
     @param
     @return
     */
    refreshInterval() {
      clearInterval(this.intervalFun);
      this.intervalFun = setInterval(() => {
        this.currIndex = this.getNextIndex(this.currIndex);
      }, this.interval);
    },

    resetItemPosition() {
      this.datas.forEach((item, index) => {
        let translate = this.translateItem(index, this.currIndex);
        const img = this.$refs[`img${index}`][0];
        img.style.zIndex = this.getZIndex(index);
        img.style.transition = `all ${this.animationInterval / 1000}s`;
        img.style.transform = 'translateX(' + translate + 'px)';
      });
    },

    getZIndex(i) {
      if (i == this.currIndex) {
        return this.datas.length
      }
      if (this.currIndex == 0 && i == this.datas.length - 1) {
        // 第一个 要处理一下最后一个
        if (this.currIndex > this.oldCurrentIndex) {
          return 1
        }
        return 2
      } else if (this.currIndex == this.datas.length - 1 && i == 0) {
        // 最后一个 要处理一下第一个
        return 2
      }
      let isInStage = Math.round(Math.abs(i - this.currIndex)) <= 1;
      if (isInStage) {
        return 2
      }
      return 1
    },

    translateItem(index, currentIndex) {
      let isInStage = Math.round(Math.abs(index - currentIndex)) <= 1;
      return this.calcCardTranslate(isInStage, index, currentIndex);
    },

    calcCardTranslate(indexInStage, index, activeIndex) {
      const parentWidth = this.$parent.$el.offsetWidth;
      if (activeIndex == 0 && index == this.datas.length - 1) {
        // 第一个 要处理一下最后一个
        return -3.51 * this.rem;
      } else if (activeIndex == this.datas.length - 1 && index == 0) {
        // 最后一个 要处理一下第一个
        return 14.13 * this.rem;
      }
      if (indexInStage) {
        return (index - activeIndex) * (8.58 + 0.24) * this.rem + 5.31 * this.rem;
      } else if (index < activeIndex) {
        return -8.58 * this.rem;
      } else {
        return parentWidth;
      }
    },
    /**
     轮播滑动清空
     @param
     @return
     */
    transClear() {
      this.transing = false;
    },
    /**
     获取下一个序号
     @param {Number} i 序号
     @return {Number} 下一个序号
     */
    getNextIndex(i) {
      if (i == this.datas.length - 1) {
        return 0;
      }
      return i + 1;
    },
    /**
     获取图片class
     @param {Number} i 序号
     @return {String} 图片class
     */
    getImgClass(i) {
      if (i == this.currIndex) {
        return 'curr';
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
.swiper {
  position: relative;
  height: 100%;
  overflow: hidden;

  > .swiper-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 8.58rem;
    height: 6.92rem;
    overflow: hidden;
    border-radius: .24rem;
    background: #F5F5F7;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      > img {
        transform: scale(1.1);
      }
    }

    > img {
      width: 100%;
      height: 5.73rem;
      transition: all 0.4s;
      object-fit: cover;
    }

    > .content {
      position: relative;
      width: 100%;
      height: 1.19rem;
      padding: 0.2rem 0;
      background: #F5F5F7;
      z-index: 2;

      > .title {
        font-weight: 500;
        font-size: 0.28rem;
        color: #000000;
        line-height: 0.39rem;
        text-align: center;
      }

      > .desc {
        margin-top: 0.12rem;
        font-size: 0.2rem;
        color: #666666;
        line-height: 0.28rem;
        text-align: center;
      }
    }
  }

  > .swiper-index {
    position: absolute;
    z-index: 99;
    bottom: 0rem;
    left: 0;
    right: 0;
    margin: auto;
    height: .16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;

    > span {
      float: left;
      position: relative;
      padding: 0 .06rem;
      width: .16rem;
      height: .16rem;
      border-radius: 50%;
      cursor: pointer;

      > span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0.16rem;
        height: .16rem;
        border-radius: 50%;
        background-color: #999;
        border: 0.02rem solid transparent;
        transition: all .3s;
      }

      > span.active {
        background-color: transparent;
        border: 0.02rem solid #999;
      }
    }
  }
}
</style>
