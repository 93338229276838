<template>
  <div id="main">
    <mv-full-page
      :isPointer="false"
      :isV="isV"
      :pages="pageCount"
      :page.sync="currentPage"
      :isCache="true"
      height="calc(100% - 0.72rem)"
      :transition="{
        duration: '500ms', // 动画时长
        timingFun: 'ease', // 动画速度曲线
        delay: '0s', // 动画延迟
      }"
      @rollEnd="onRollEnd"
      :config="{
        disabled: this.localValue
      }"
    >
      <template #page1>
        <div class="video">
          <div class="video-container">
            <video class="video" ref="myVideo" :src="videoPath" muted autoplay loop
                   @contextmenu.prevent
                   controls playsinline preload="auto" x5-video-player-type="h5" webkit-playsinline="true" x5-playsinline="true"
                   :poster="videoPath + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast'">
            </video>
          </div>
          <div class="copyright">广告审查批准文号:苏械广审(视)第270213-02323号 盆底康复治疗仪 医疗器械注册号:苏械注准20252090043 盆底评估训练软件:苏械注准20242212280<br/>请仔细阅读产品说明书或者在医务人员的指导下购买和使用 禁忌内容或者注意事项详见说明书 南京麦豆健康科技有限公司
          </div>
        </div>
      </template>
      <template #page2>
        <div class="banner">
          <full-swiper :hover-scale="false" :datas="bannerList"></full-swiper>
        </div>
      </template>
      <template #page3>
        <div class="stage-mode">
          <img class="bg" alt :src="require('@/assets/img/product/e10/stage-mode-bg.png')"/>
          <div class="title">盆底全周期训练</div>
          <img-swiper :datas="imgBanner"></img-swiper>
        </div>
      </template>
      <template #page4>
        <div class="train">
          <div class="title">无械 + 有械</div>
          <div class="sub-title">科学多维 分阶修复</div>
          <video v-animate="{
             name: 'zoomIn',
          }"
                 src="//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-e10-1.mp4" muted autoplay loop
                 @contextmenu.prevent
                 playsinline="true" preload="auto" x5-video-player-type="h5" webkit-playsinline="true" x5-playsinline="true"></video>
        </div>
      </template>
      <template #page5>
        <div class="no-equip">
          <img v-animate="{
             name: 'fadeInUp',
          }" alt :src="require('@/assets/img/product/e10/no-equip.png')"/>
          <div class="right">
            <div class="title" v-animate="{
             name: 'fadeInUp',
             delay: 500,
          }">无械跟练
            </div>
            <div class="sub-title" v-animate="{
             name: 'fadeInUp',
             delay: 700,
          }">定制课程，自主练习
            </div>
          </div>
        </div>
      </template>
      <template #page6>
        <div class="equip">
          <div class="top">
            <div v-animate="{
             name: 'fadeInUp',
          }" class="title">有械训练
            </div>
            <div v-animate="{
             name: 'fadeInUp',
             delay: 200
          }" class="sub-title">盆底治疗 腹直肌修复
            </div>
          </div>
          <img-swiper-v2 v-animate="{
             name: 'fadeInUp',
             delay: 400
          }" :datas="equipList"></img-swiper-v2>
        </div>
      </template>
    </mv-full-page>
  </div>
</template>

<script>
import '@/assets/css/animate.min.css';
import 'mv-full-page/lib-dist/mv-full-page.css';
import MvFullPage from 'mv-full-page';
import Vue from 'vue';
import { VideoPlay } from '@/components/video';
import { FullSwiper } from '@/components/full-swiper';
import { ImgSwiper } from '@/components/img-swiper';
import { ImgSwiperV2 } from '@/components/img-swiper-v2';
import directives from '@/utils/directives';

Vue.use(directives);
Vue.use(MvFullPage);

export default {
  data() {
    return {
      localValue: this.value,
      isV: true,
      currentPage: 1,
      pageCount: 6,
      videoPath: `//rightinhome.oss-cn-hangzhou.aliyuncs.com/website/product-e10.mp4`,
      bannerList: [
        {
          path: require('@/assets/img/product/e10/banner-1.png'),
        },
        {
          path: require('@/assets/img/product/e10/banner-2.png'),
        },
      ],
      imgBanner: [
        {
          path: require('@/assets/img/product/e10/stage-mode-1.png'),
        },
        {
          path: require('@/assets/img/product/e10/stage-mode-2.png'),
        },
        {
          path: require('@/assets/img/product/e10/stage-mode-3.png'),
        },
        {
          path: require('@/assets/img/product/e10/stage-mode-4.png'),
        },
      ],
      equipList: [
        {
          path: require('@/assets/img/product/e10/equip-1.png'),
          title: '多维度盆底评估',
          desc: '标准快速筛查多维 盆底评估清晰呈现',
        },
        {
          path: require('@/assets/img/product/e10/equip-2.png'),
          title: 'AI 定制专项方案',
          desc: 'AI 大数据针对不同用户痛点 定制个性化训练方案',
        },
        {
          path: require('@/assets/img/product/e10/equip-3.png'),
          title: '腹肌监测',
          desc: '腹肌监测提醒 反馈腹肌参与情况',
        },
        {
          path: require('@/assets/img/product/e10/equip-4.png'),
          title: '六大训练模式',
          desc: '专项科学治疗 提升盆底肌群力',
        },
      ],
    };
  },
  props: {
    value: { //数据源（必填）
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$parent.$emit('rollInit');
  },
  methods: {
    onRollEnd(page) {
      this.$parent.$emit('rollPage', page);
      if (page === this.pageCount) {
        this.$parent.$emit('rollEnd');
      } else {
        this.$parent.$emit('rollTop');
      }
    },
  },
  watch: {
    value(val) {
      this.localValue = val;
    },
    currentPage(val) {
      if (val === 1) {
        this.localValue = false;
      }
    },
  },
  components: {
    FullSwiper,
    ImgSwiper,
    ImgSwiperV2,
  },
};
</script>

<style lang="scss" scoped>
.full-page-wrapper {
  top: 0.72rem;

  // 强制第一页全屏
  /deep/ .all-page .page:nth-child(1) {
    height: calc(100% / 6 + 0.72rem) !important;
    margin-top: -0.72rem !important;
  }
}

.video {
  position: relative;
  width: 100%;
  height: 100%;

  > .video-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    > .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > .copyright {
    position: absolute;
    bottom: 0.24rem;
    left: 0;
    width: 100%;
    font-weight: 400;
    font-size: 0.12rem;
    color: #FFFFFF;
    line-height: 0.17rem;
    text-align: center;
    pointer-events: none;
  }
}

.banner {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
}

.stage-mode {
  position: relative;
  width: 100%;
  height: 100%;
  background: #FAFBFE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  > .title {
    font-weight: 500;
    font-size: 0.56rem;
    color: #000000;
    z-index: 2;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .swiper {
    width: 100%;
    height: 6.38rem;
    z-index: 2;
    margin-bottom: 0.72rem;
  }
}

.train {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .title {
    margin-top: 0.12rem;
    font-weight: 500;
    font-size: 0.56rem;
    color: #000000;
    line-height: 0.78rem;
    text-align: center;
  }

  > .sub-title {
    font-weight: 500;
    font-size: 0.28rem;
    color: #000000;
    line-height: 0.39rem;
    text-align: center;
    margin-bottom: 0.12rem;
  }

  > video {
    width: calc(100% - 2 * 0.9rem);
    border-radius: 0.24rem;
    object-fit: cover;
  }
}

.no-equip {
  position: relative;
  width: 100%;
  height: 100%;
  background: #FAFBFE;
  display: flex;
  align-items: center;

  > img {
    width: 10.3rem;
    margin-left: 1.62rem;
    margin-right: 2.73rem;
  }

  > .right {
    > .title {
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
    }

    > .sub-title {
      margin-top: 0.12rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.39rem;
    }
  }
}

.equip {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > .top {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .title {
      font-weight: 500;
      font-size: 0.56rem;
      color: #000000;
      line-height: 0.78rem;
      text-align: center;
    }

    > .sub-title {
      font-weight: 500;
      font-size: 0.28rem;
      color: #000000;
      line-height: 0.39rem;
      text-align: center;
    }
  }

  > .swiper {
    width: 100%;
    height: 7.48rem;
    margin-bottom: 0.24rem;
  }
}
</style>
